import * as React from "react";
import Helmet from "react-helmet";

import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import { PageWrapper, TextWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import NextButton from "../components/next-button";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;

  }
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 25%;
  max-width: 175px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
  color: #020f73;
  font-family: sans-serif;
`;

// markup
const OurGrowthPage = () => {
  return (
    <>
      <Helmet>
        {" "}
        <title>Growth Strategy | True Wealth Group</title>
        <meta
          name="description"
          content="Growth strategy of True Wealth Group"
        />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <PageWrapper>
        <Navigation></Navigation>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
          loading="eager"
        />
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%", aspectRatio: "1/1" }}
            alt="True Wealth Group logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <h1>Growth Strategy</h1>
        </ContentWrapper>
        <TextWrapper>
          We believe interweaving True financial planning and True wealth
          management can be a powerful combination for clients. This compelling
          proposition is fueling our multifaceted growth strategy that includes
          acquisitions, joint ventures and organic growth.
        </TextWrapper>
        <TextWrapper>
          <h2>Acquisitions</h2>
          <p>
            Many of the principals we have worked with via our consultancy
            services (and a lot of other ‘good guys’ in the industry) are
            approaching their own retirement and are worried about who will keep
            their promises to their clients and staff when they no longer can.
            We are proud that many of them turn to True Wealth for a
            conversation.
          </p>
          <p>
            {" "}
            Since launch, the True Wealth Group has been funded by its founders
            and private investors and remains in control of its own destiny. It
            has no corporate or institutional shareholders with vested interests
            to influence how we structure client propositions or how we treat
            acquired firms. We think this reduces the potential for conflict of
            interest and helps ensure that a client’s best interests are put
            first and that advisers remain truly impartial and independent.
          </p>
          <p>
            {" "}
            We will continue to acquire great businesses at the rate our capital
            position allows. If you can get behind our Vision, Mission and
            Purpose and want to sell to a party without any hidden agendas that
            is truly client centric, <Link to="/contact">drop us a line</Link>.
          </p>{" "}
        </TextWrapper>
        <TextWrapper>
          <h2>Joint Ventures</h2>
          <p>
            A significant part of our growth is fuelled by the high-quality
            leads generated from our joint venture relationships. We have a
            proven track record of establishing successful joint venture and
            introducer relationships such as the{" "}
            <a
              href="https://lawsoc-nifa.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Law Society (NI) Financial Advice
            </a>
            ,{" "}
            <a
              href="https://tagfinancialplanning.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              TAG Financial Planning
            </a>{" "}
            and{" "}
            <a
              href="https://cutterandco-fp.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cutter &amp; Co Financial Planning
            </a>
            . This enables us to build income and value for our JV partners and
            puts us in an enviable position so as to be able to provide a ready
            supply of qualified, ‘warm’ leads to financial planners.
          </p>{" "}
          <p>
            We are proud that hundreds of professionals across the UK have
            chosen us to be their introducer partner either directly or via
            joint ventures and that they trust us to provide financial planning
            services when their own name is above the door. If you have a
            business with affluent/high net worth clients and would like to know
            how we can help you and your clients, please{" "}
            <Link to="/contact">get in touch</Link>.
          </p>{" "}
        </TextWrapper>
        <TextWrapper>
          <h2>Organic Growth and Recruitment</h2>
          <p>
            One of our 2030 goals is to be the employer of choice in our sector
            and we anticipate that many planners and technical staff will be
            internally ‘home grown’ via the True Wealth Academy. The firms we
            acquire will also bring with them great staff whose careers we are
            keen to develop and align with our culture and values. Last, but by
            no means least, is our desire to attract the profession's top talent
            as our reputation and awareness of our project grows.
          </p>
        </TextWrapper>
        <NextButton url="/careers" name="Careers" />
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default OurGrowthPage;

export const Head = () => (
  <>
    <title>Growth Strategy | True Wealth Group</title>
    <meta name="description" content="Growth strategy of True Wealth Group" />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
